import React from "react";
import "./landing.scss";

import Aos from "aos";
import "aos/dist/aos.css";

import jcurve from "../../assets/img/jcurve.svg";
import { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faYoutube,
  // faFacebook,
  faTwitter,
  faGithub,
  faBitcoin,
  faDiscord,
  faTelegram
} from "@fortawesome/free-brands-svg-icons";
// import { Telegram } from "@material-ui/icons";

import {
  Header,
  Features,
  Coinspecs,
  // Roadmap,
  // Testimony,
  Exchange,
  // Contact,
  Hero,
} from "../../containers";


const year = new Date().getFullYear();

export const LandingPage: React.FC = () => {
  useEffect(() => {
    Aos.init({ duraton: 5000 });
  }, []);

  return (
    <>
      <Header />

      <Hero />

      <div className="section-b">
        {/* Features */}
        <Features />



        {/* Coinspecs */}
        <Coinspecs />
        <img src={jcurve} alt="curve" className="jcurve" />
      </div>



      <div className="section-c">

        {/* <Exchange /> */}
        <Exchange />

        <section id="wallet">
          <h5 className="wallet">Download Wallet</h5>
          <div data-aos="fade-right" className="wallet-wrapper">
            <div className="wlt-item">
              <i className="fa fa-linux" aria-hidden="true"></i>
              <div className="b-group">
                <a href="https://www.skoncoin.com/wallet/skoncoincore-1.0.0-linux64.tar.gz">64bit</a>
                <a href="https://www.skoncoin.com/wallet/skoncoincore-1.0.0-linux32.tar.gz">32bit</a>
              </div>
            </div>
            <div className="wlt-item">
              <i className="fa fa-windows" aria-hidden="true"></i>
              <div className="b-group">
                <a href="https://www.skoncoin.com/wallet/skoncoincore-1.0.0-win64.zip">64bit</a>
                <a href="https://www.skoncoin.com/wallet/skoncoincore-1.0.0-win32.zip">32bit</a>
              </div>
            </div>
            <div className="wlt-item">
              <i className="fa fa-apple" aria-hidden="true"></i>
              <a href="https://www.skoncoin.com/wallet/skoncoincore-1.0.0-osx.dmg">Download</a>
            </div>
            <div className="wlt-item">
              <i className="fa fa-globe" aria-hidden="true"></i>
              <a href="/#wallet">Create Wallet</a>
            </div>
          </div>
        </section>


        {/* <img src={sq} alt="sq" className="sq-bg" /> */}
      </div>




      {/* Footer */}


      <div className="footer">
        <h2>Join Our Community</h2>
        <div className="c-footer">
          <ul className="list-social">
            <a href="/"><li> <FontAwesomeIcon icon={faTwitter} size="lg" /> Twitter</li></a>

            <a href="/"><li> <FontAwesomeIcon icon={faTelegram} size="lg" />Telegram</li></a>
            <a href="/"><li> <FontAwesomeIcon icon={faDiscord} size="lg" />Discord</li></a>
            <a href="/"><li> <FontAwesomeIcon icon={faYoutube} size="lg" />Youtube</li> </a>
            <a href="/"><li> <FontAwesomeIcon icon={faBitcoin} size="lg" />Bitcointalk</li></a>
            <a href="https://github.com/skoncoin/skoncoin" target='_blank' rel="noreferrer noopener" ><li> <FontAwesomeIcon icon={faGithub} size="lg" />Github</li> </a>

          </ul>


        </div>

        <div className="sm-footer">
          <span>Copyright &copy; {year} | All Right Reseved</span>
        </div>
      </div>
    </>
  );
};
