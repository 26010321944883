

import React from 'react'
import Particles from 'react-particles-js';

import {ParticleSimpleConfig} from '../../helpers/particleConfig'

interface indexProps {

}

export const ParticleBackground: React.FC<indexProps> = () => {
        return (

            <> 
            <Particles params={ParticleSimpleConfig}/> 
            </>
        );
}