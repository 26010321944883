import React from 'react'
import './exchange.scss'

export function Exchange() {
  return (
    <section id="exchange">
      <h5 className="exchange">Exchange</h5>
      <div data-aos="fade-right" className="exchange-wrapper">
        <a href="https://www.globe-dex.com/exchange/SKO_BTC" target={"_blank"} rel="noopener noreferrer"> <div className="ex-item">
          <img src="/assets/globedex.png" alt="GlobeDex" />
        </div>
        </a>
        <a href="/" rel="noreferrer">
          <div className="ex-item">
            <img src="/assets/fbox.png" alt="finexbox" />
          </div>
        </a>
        <div className="ex-item">
          <img src="/assets/binance.png" title="coming soon" alt="binance" />
        </div>
        <div className="ex-item">
          <img src="/assets/stex.png" title="coming soon" alt="stex" />
        </div>
        <div className="ex-item">
          <img src="/assets/gate.png" title="coming soon" alt="gate" />
        </div>
        <div className="ex-item">
          <img src="/assets/bittrex.png" title="coming soon" alt="bittrex" />
        </div>
      </div>
    </section>
  )
}
