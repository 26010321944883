import React from 'react';
import './App.css';  

import {LandingPage} from './pages'

function App() {
  return (
    <div className="App"> 
   
      <LandingPage />
    </div>
  );
}

export default App;
