import React, { useEffect, useState } from "react";

import { Menu } from "@material-ui/icons";
import "./Header.scss";
// import logo from '../../assets/img/logo.png'
function Index() {
  const [isNavSroll, setisNavSroll] = useState(false);

  const navScroll = () => {
    if (window.scrollY >= 100) {
      setisNavSroll(true);
    } else {
      setisNavSroll(false);
    }

    // set active base on section
    let current = "";
    const sections = document.querySelectorAll("section");
    const sectNav = document.querySelectorAll(".center ul li");
    const h5Active = document.querySelectorAll("h5");

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;
      // eslint-disable-next-line no-restricted-globals
      if (pageYOffset >= sectionTop - sectionHeight / 4) {
        current = section.getAttribute("id")!;
      }
    });

    // remove old active and set new active
    sectNav.forEach((li) => {
      li.classList.remove("active");
      if (li.classList.contains(current)) {
        li.classList.add("active");
      }
    });

    h5Active.forEach((h5) => {
      h5.classList.remove("active");
      if (h5.classList.contains(current)) {
        h5.classList.add("active");
      }
    });

  };


  const mobileNav = () => {
    // console.log('here')
    const mNav = document.querySelector(".n-mobile")!;
    mNav.classList.toggle("mobile");
  };


  //    hide menu after click li item - n-mobile
  useEffect(() => {

    const moUlLi = document.querySelectorAll(".n-mobile.center > ul > li")!;
    moUlLi.forEach(hli => {
      hli.addEventListener('click', () => {
        mobileNav()
      })
    })

    return () => {

      const moUlLi = document.querySelectorAll(".n-mobile.center > ul > li")!;
      moUlLi.forEach(hli => {
        hli.removeEventListener('click', () => {
          mobileNav()
        })
      })

    };
  }, []);


  useEffect(() => {
    window.addEventListener("scroll", navScroll);
    return () => {
      window.removeEventListener("scroll", navScroll);
    };
  }, []);

  return (
    <div>
      <div className={isNavSroll ? "nav nav-sc" : "nav"}>
        <div className="center">
          <ul>
            <li className="home active">
              <a href="/#">Home</a>
            </li>
            <li className="features">
              <a href="/#features">Features</a>
            </li>
            <li className="coin-specs">
              <a href="/#coin-specs">Coin Specs</a>
            </li>
            {/* <li className="roadmap">
              <a href="/#roadmap">RoadMap</a>
            </li> */}
            <li className="exchange">
              <a href="/#exchange">Exchange</a>
            </li>
            <li className="wallet">
              <a href="/#wallet">Wallet</a>
            </li>
            <li className="contact">
              <a href="/#contact">Contact</a>
            </li>
          </ul>
        </div>

        <div onClick={() => mobileNav()} className="n-menu">
          <Menu fontSize="large" />
        </div>

        <div className="n-mobile center">
          <ul>
            <li className="home active">
              <a href="/#">Home</a>
            </li>
            <li className="features">
              <a href="/#features">Features</a>
            </li>
            <li className="coin-specs">
              <a href="/#coin-specs">Coin Specs</a>
            </li>
            {/* <li className="roadmap">
              <a href="/#roadmap">Road Map</a>
            </li> */}
            <li className="exchange">
              <a href="/#exchange">Exchange</a>
            </li>
            <li className="wallet">
              <a href="/#wallet">Wallet</a>
            </li>
            <li className="contact">
              <a href="/#contact">Contact</a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  );
}

export const Header = React.memo(Index)