import React from 'react'
import './coinspecs.scss'

export function Coinspecs() {
  return (

    <section id="coin-specs"  >
      <h5 className="coin-specs">Coin Specification</h5>
      <div className="specs-wrapper">
        <div className="spec-left">
          <ul>
            <li><span>Name:</span> Skoncoin</li>
            <li><span>Ticker:</span> SKO</li>
            <li><span>Premine:</span> 100 000 000 SKO</li>
            <li><span>Block Reward:</span> 5 SKO</li>
            <li><span>Masternode Reward:</span> 60%</li>
            <li><span>Masternode Collateral:</span> 1000 SKO</li>
          </ul>
        </div>
        <div className="spec-right">

          <ul>
            <li><span>Confirmation:</span> 6 Block </li>
            <li><span>Maturity:</span> 30 </li>
            <li><span>Address Letter:</span> S </li>
            <li><span>Algo:</span> x11 </li>
            <li><span>RPC Port:</span> 8283 </li>
            <li><span>P2P Port:</span> 8284</li>
          </ul>
        </div>
      </div>
    </section>

  )
}
