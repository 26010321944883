import React from 'react'
import {
  Group,
  Lock,
  Public,
  // GridOn,
} from "@material-ui/icons";

import './features.scss'

export function Features() {
  return (
    <section id="features">
      {/* <h5 className="features">Features</h5> */}
      <div className="features-w">
        <div className="features-i" data-aos="fade-down" data-aos-delay="900">
          <div className="logo-i">
            <Group />
          </div>
          <h3>Peer To Peer</h3>
          <p>
            Skoncoin uses a decentralized blockchain network,so there is no centralized third party to trust.
          </p>
        </div>

        <div data-aos="fade-down" data-aos-delay="600" className="features-i">
          <div className="logo-i">
            <Public />
          </div>
          <h3>Global</h3>
          <p>
            You can send money to anywhere in the world with high speed
            transaction and minimum fees.
          </p>
        </div>

        <div data-aos="fade-down" data-aos-delay="300" className="features-i">
          <div className="logo-i">
            <Lock />
          </div>
          <h3>Secure</h3>
          <p>Advanced encryption and comprehensive security.</p>
        </div>
      </div>
    </section>

  )
}
