import React from 'react'
import './hero.scss'
import {

  ParticleBackground,
} from "../../components";

import {
  Search
} from "@material-ui/icons";

// import right_img from "../../assets/img/logo-hero.png";
import right_img from "../../assets/img/tech_logo.svg";

export function Hero() {
  return (

    <section id="home" className="intro-section">

      <ParticleBackground />
      <div className="intro-left">
        <h1>Skoncoin (SKO)</h1>
        <h2>Your dream currency</h2>
        <p>
          What is Skoncoin? is a global blockchain network that
          connect it’s users with a fast, secure and more
          cost-effective payment network. Unlike Bitcoin ,
          it does not use SHA256 as its proof of work (POW).
        </p>
        <div className="link-wrapper on-light">
          <a href="https://explorer.skoncoin.com" target="_blank" rel="noreferrer noopener" className="explorer border-gradient">
            <Search /> Block Explorer
          </a>
        </div>
      </div>
      <div className="intro-right">
        <div className="fea-logo">
          <img src={right_img} alt="fea" />
        </div>
      </div>


    </section>

  )
}
